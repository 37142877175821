import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import ParseContent from '../components/ParseContent.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',				//默认http://localhost:8081/#/为登录路由
            component: Login,		//该路由对应的相应组件
            meta: {
                title: 'AI智能统计自动识别系统',
            }
        },
        {
            path: '/home',			//配置http://localhost:8081/#/home为主界面路由
            component: ParseContent,
            meta: { title: 'AI智能统计自动识别系统' }
        }

    ]
})

router.beforeEach((to, from, next) => {
    console.log("router before")
    window.document.title = to.meta.title == undefined ? '默认标题' : to.meta.title

    if (to.matched.some(route => route.meta && route.meta.requiresAuth)) {
        if (!this.$storage.getStorage("userInfo")) {  // 没有登录信息跳转到登录页
            next({
                path: "/",
                query: { redirect: to.fullPath }  // 'to.fullPath'跳转到登录之前页面的路径
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;