import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vconsole from 'vconsole';
import Vue from 'vue';
import App from './App.vue';
import VueClipboard from 'vue-clipboard2';
import router from './router/router' 			//导入router.js

// const VConsole = require('vconsole')
// eslint-disable-next-line no-new
// new VConsole();


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueClipboard);
new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
