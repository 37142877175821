import axios from 'axios';

// 通用公用方法
const req = (method, url, params) => {
    // console.log(url);
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.getItem('logintoken')
        },
        data: params,
        traditional: true,
        transformRequest: [
            function (data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};

export const sendJson = (method, url, params) => {
    // 将 params 对象转换为 JSON 字符串
    const jsonData = JSON.stringify(params);
    // 发送 POST 请求
    return axios.post(url, jsonData, {
        headers: {
            'Content-Type': 'application/json' // 告诉服务器发送的是 JSON 数据
        }
    })
};

export {
    req
}
