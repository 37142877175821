<template>
  <!-- <div class="hello" style="background-color: aliceblue" height="100%"> -->
  <div :class="selectClass()">
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <h2 style="color: #67c23a">AI智能统计</h2>
      </el-col>
      <el-col :span="22" :offset="1" style="height: 25px; font-size: 12px">
        <font style="color: #67c23a">会员到期日为:</font>{{ endTime }}
        <font style="color: #67c23a">管理员联系方式：</font><u>{{ pageInfo.contactDesc }}</u>
      </el-col>
      <el-col>
        <el-button type="text" size="mini" @click="logout()" style="color: red">退出登入</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24" class="edit-col">
        <el-select v-model="tmpClzType" :placeholder="pageInfo.changeClz" class="fixed-width-button small-font-select"
          @change="handleSelectionChange">
          <el-option v-for="item in clzTable" :key="item.clz" :label="item.clz" :value="item.clz">
          </el-option>
        </el-select>
        <!-- <el-button type="success" width="20px" align="left" size="mini" @click="changeSort()"
          class="fixed-width-button">切换科目</el-button> -->
        <span width="20px"><b>{{ clzType }}</b></span>
        <el-button type="success" width="20px" size="mini" @click="showClzEditForm()" class="fixed-width-button">{{
          pageInfo.addClz }}</el-button>
      </el-col>
    </el-row>

    <el-row :gutter="10" style="margin-top: 2%;border-top: 0.5px solid #b8b8b8;">
      <el-col :span="24" class="edit-col">
        <el-select v-model="tmpCustomerType" :placeholder="pageInfo.changeCs"
          class="fixed-width-button small-font-select" @change="handleCustomerSelectionChange">
          <el-option v-for="item in custTable" :key="item.cust" :label="item.cust" :value="item.cust"
            v-if="item.type === '客户'">
          </el-option>
        </el-select>

        <span width="20px"><b>{{ customerType }}</b></span>
        <el-button type="success" width="20px" size="mini" @click="showCustEditForm()" class="fixed-width-button">{{
          pageInfo.addCs }}</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <el-form :model="ruleForm" ref="ruleForm">
          <el-form-item style="margin-bottom: 0%; padding-bottom: 0%">
            <el-input type="textarea" @dblclick="selectText" v-model="ruleForm.desc" :placeholder="pageInfo.parseDesc"
              style="font-size: 10px" :rows="10" :autosize="{ minRows: 6, maxRows: 40 }"
              :disabled="isSelectCust()"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <div>
      <el-row>
        <el-col :span="20" :offset="2">
          <pre style="
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              color: red;
              text-align: left;
              font-size: 10px;
            ">{{ sendResultMsg }}</pre>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="10" style="padding-bottom: 20px">
      <el-col :span="7" :offset="4">
        <el-button type="success" size="small" @click="submitForm('ruleForm')">{{
          pageInfo.parseButton
        }}</el-button>
      </el-col>
      <el-col :span="7" :offset="2">
        <el-button type="success" size="small" @click="resetForm('ruleForm')">{{
          pageInfo.clearParseButton
        }}</el-button>
      </el-col>
    </el-row>

    <el-container>
      <el-aside width="50%" style="overflow-x: hidden" class="custom-row-border">
        <el-row :gutter="1" style="border-radius: 4px">
          <el-col :span="24" class="edit-col">
            <span width="20px" style="font-size: 12px"><b>{{ isTotalCustModel ? "总单" : customerType }}</b></span>
            <!-- <span width="20px" style="font-size: 12px"><b>{{ pageInfo.cv }}{{ getcnum() }}</b></span> -->
            <!-- <span width="20px" style="font-size: 12px"><b>{{ pageInfo.dv }}{{ dnum }}</b></span> -->
            <el-button type="success" width="20px" size="mini" @click="changeToTotal()"
              style="transform: scale(0.8, 0.8)">{{ pageInfo.displayTotal }}</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="1" style="border-radius: 4px">
          <el-col :span="24"><span width="20px" style="font-size: 14px"><b>{{ pageInfo.rightTotal }}：{{
            getCustTableTotal() }}</b></span></el-col>
        </el-row>
        <el-row :gutter="1" style="border-radius: 4px">
          <el-col :span="24" class="edit-col">
            <el-button type="success" width="20px" align="left" size="mini" @click="changeSort()"
              style="transform: scale(0.8, 0.8)">切换</el-button>

            <el-button type="success" width="20px" size="mini" @click="showUpdateForm(tableData)"
              style="transform: scale(0.8, 0.8)">修改订单</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="24">
            <!-- 扣库表 -->
            <el-table :data="getCustTable(sortType)" :header-cell-style="normalHeaderCellStyle" size="mini" stripe
              border :row-style="{ height: '5px' }" :cell-style="{ padding: '0' }" style="font-size: 8px">
              <el-table-column prop="orderId" :label="orderIdName" align="center">
              </el-table-column>
              <el-table-column prop="number" :label="numberName" align="center">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-aside>
      <el-aside width="50%" style=" overflow-x: hidden" class="custom-row-border">
        <div style="padding-bottom: 2px">
          <el-row>
            <el-col :span="24" class="edit-col">
              <el-button type="success" size="mini" @click="showAppraiseForm(repoNum)"
                class="small-font-button">评估</el-button>
              <el-input v-model="repoNum" size="mini" class="small-font-input"
                :placeholder="pageInfo.reducePlaceHolder"></el-input>
              <el-button type="success" size="mini" @click="reduceRepo()" class="small-font-button">{{
                pageInfo.reduceButton }}</el-button>
            </el-col>
          </el-row>
        </div>
        <!-- <el-row style="padding-bottom: 10px">
          <span width="20px" style="font-size: 12px;"><b>{{ pageInfo.reduceButtonDesc }}{{ mafan() }}</b></span>
        </el-row> -->
        <el-row style="border-bottom: 1px solid #b8b8b8">
          <el-col :span="12">
            <span width="20px" style="font-size: 8px"><b>最大亏损：{{ maxKS }}</b></span>
          </el-col>
          <el-col :span="12">
            <span width="20px" style="font-size: 8px"><b>最大盈利：{{ maxYL }}</b></span>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #b8b8b8">
          <el-col class="edit-col">
            <el-col :span="17" align="center">
              <span width="20px" style="font-size: 12px"><b>{{ this.cjType }}</b></span>
            </el-col>
            <!-- <el-col :span="6" class="edit-col" :offset="1"> -->
            <el-select v-model="tmpCjType" placeholder="切换上家" class="fixed-width-button small-font-select"
              @change="handleCjSelectionChange">
              <el-option v-for="item in custTable" :key="item.cust" :label="item.cust" :value="item.cust"
                v-if="item.type !== '客户'">
              </el-option>
            </el-select>
            <!-- </el-col> -->
          </el-col>
        </el-row>
        <div>
          <el-row style="border-radius: 4px; border-bottom: 1px solid #b8b8b8">
            <el-col class="edit-col">
              <el-button type="success" size="mini" @click="doCopy(getReduceTableData(), true)"
                class="small-font-button-mini">
                {{ pageInfo.copyButton }}
              </el-button>
              <el-button type="success" size="mini" @click="clearRepo()" class="small-font-button-mini">
                {{ pageInfo.clearButton }}
              </el-button>
              <!-- <el-button type="success" size="mini" @click="clearRepo()" class="small-font-button-mini">
                显示总表
              </el-button> -->
            </el-col>
          </el-row>
          <el-row style="border-radius: 4px; border-bottom: 1px solid #b8b8b8">
            <el-col class="edit-col" :span="24">
              <font style="font-size: 10px"> {{ pageInfo.reduceButtonDesc }}{{ reduceTotalValue }}</font>

            </el-col>
            <!-- <el-col class="edit-col" :span="11">
              <font style="font-size: 10px">N:</font>
            </el-col> -->
          </el-row>
          <el-row style="border-radius: 4px; border-bottom: 1px solid #b8b8b8">
            <el-col class="edit-col" :span="11">
              <font style="font-size: 10px">{{ pageInfo.cacheTimeDesc }}{{ cacheTime }}
              </font>
            </el-col>
            <el-col class="edit-col" :span="11">
              <font style="font-size: 10px">{{ pageInfo.caculDesc }}{{ totalVal() }}</font>
            </el-col>
          </el-row>
          <el-row style="border-radius: 4px; border-bottom: 1px solid #b8b8b8">
            <el-col class="edit-col">
              <el-button type="success" size="mini" :disabled="isReduce" @click="doSave" class="small-font-button-mini">
                {{ pageInfo.saveButton }}
              </el-button>
              <el-button type="success" size="mini" @click="reduceCacheRepo()" :disabled="isShow()"
                class="small-font-button-mini">
                {{ pageInfo.reduceCacheButton }}
              </el-button>
              <el-button type="success" size="mini" @click="undoSaveCache()" :disabled="isShowUndoBtn()"
                class="small-font-button-mini">
                撤回存单
              </el-button>
            </el-col>
          </el-row>
        </div>
        <!-- 订单总计 -->
        <el-row :gutter="1" style="border-radius: 4px">
          <el-col :span="22" :offset="2">
            <span width="20px" style="font-size: 10px"><b> {{ pageInfo.reduceTotal }} {{ caculLeft() }}</b></span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table :data="getReduceTableData()" :header-cell-style="normalHeaderCellStyle" stripe border size="mini"
              :row-style="{ height: '4px' }" :cell-style="{ padding: '0' }"
              style="font-size: 8px; overflow-x: hidden; padding: 0">
              <el-table-column prop="orderId" :label="orderIdName" align="center" min-width="50%">
              </el-table-column>
              <el-table-column prop="number" :label="numberName" align="center" min-width="50%">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-button type="success" size="mini" @click="showTotalForm()" width="100%">结算</el-button>
          </el-col>
        </el-row>

      </el-aside>
    </el-container>
    <div style="padding-bottom: 20px">
      <el-row :gutter="10">
        <el-col :span="24" style="background-color: aliceblue">
          <el-card shadow="hover">
            <div>
              <pre>{{ pageInfo.desc }}</pre>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="showTotalFormVisible" width="100%" :before-close="closeClzDialog" class="pg">
      <el-row>
        <el-col :span="3" class="edit-col">

          <span width="100px"><b>{{ clzType }}</b></span>
        </el-col>
        <el-col :span="18" class="edit-col">
          <el-input v-model="chosenOrder" size="mini" :placeholder="pageInfo.hzChosen">选择编号</el-input>
          <el-button type="success" size="mini" @click="showTotal()">{{ pageInfo.hzCheck
            }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="huizong">
          <!-- //汇总表  huizong-->
          <el-table :data="totalTable" border stripe :header-cell-style="getHeaderCellStyle" :cell-style="getCellStyle"
            :span-method="objectSpanMethod" style="padding: 0%">
            <el-table-column label="" align="center">
              <el-table-column prop="type" min-width="6%" align="center" height="0"></el-table-column>
              <el-table-column prop="cust" min-width="20%" height="0"></el-table-column>
            </el-table-column>
            <el-table-column prop="v" :label="pageInfo.vv" align="center" min-width="28%">
            </el-table-column>
            <el-table-column prop="m" :label="pageInfo.mv" align="center" min-width="28%">
            </el-table-column>
            <el-table-column prop="n" :label="pageInfo.nv" align="center" min-width="28%">
            </el-table-column>
            <el-table-column prop="u" :label="pageInfo.uv" align="center" min-width="20%">
            </el-table-column>
            <el-table-column prop="w" :label="pageInfo.wv" align="center" min-width="28%">
            </el-table-column>
            <el-table-column label="" align="center" min-width="28%">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">复制</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :visible.sync="editClzFormVisible" width="100%" :before-close="closeClzDialog"
      style="padding-left: 10%; padding-right: 10%">
      <el-table :data="tmpClzTable" style="width: 100%" max-height="50%" border>
        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <el-button @click.native.prevent="deleteRow(scope.$index, tmpClzTable)" icon="el-icon-delete-solid"
              size="small">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="clz" :label="pageInfo.km" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.clz" size="small" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" align="center">
        <!-- 左边的加号按钮 -->
        <el-button size="small" type="success" @click="addClzRow(tmpClzTable)" class="title">{{ pageInfo.addkm
          }}</el-button>
        <!-- 右边的编辑按钮 -->
        <el-button size="small" type="success" class="title" @click="submitClzForm()" width="100%">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editCustFormVisible" width="100%" @click="closeCustDialog" class="pg">
      <el-table :data="tmpCustTable" max-height="50%" :header-cell-style="biggerHeaderCellStyle" border size="mini"
        :row-style="{ height: '5px' }" :cell-style="{ padding: '0' }" style="font-size: 8px">
        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <el-button @click.native.prevent="deleteRow(scope.$index, tmpCustTable)" icon="el-icon-delete-solid"
              size="small">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="客户/上家" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.type" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
        <el-table-column prop="cust" label="用户名称" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cust" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
        <el-table-column prop="g" :label="pageInfo.gv" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.g" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>

        <el-table-column prop="z" :label="pageInfo.zv" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.z" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" align="center">
        <!-- 左边的加号按钮 -->
        <el-button size="small" type="success" @click="addClzRow(tmpCustTable)" class="title">新增</el-button>
        <!-- 右边的编辑按钮 -->
        <!-- <el-button size="small" type="success" class="title" @click="submitCustForm()" width="100%">清除设置</el-button> -->
        <el-button size="small" type="success" class="title" @click="submitCustForm()" width="100%">保存设置</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editTotalFormVisible" width="100%" @click="closeCustDialog"
      style="padding-left: 10%; padding-right: 10%">
      <el-table :data="tmpTotalTable" max-height="50%" :header-cell-style="normalHeaderCellStyle" border size="mini"
        :row-style="{ height: '5px' }" :cell-style="{ padding: '0' }" style="font-size: 8px">
        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <el-button @click.native.prevent="deleteRow(scope.$index, tmpCustTable)" icon="el-icon-delete-solid"
              size="small">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="客户/上家" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.type" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
        <el-table-column prop="cust" label="用户名称" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cust" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
        <el-table-column prop="g" label="G" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.g" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
        <el-table-column prop="z" label="Z" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.z" size="mini" controls-position="right"
              :disabled="isClzDisable(scope.row.password)" />
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <!-- 左边的加号按钮 -->
        <el-button size="small" type="success" @click="addClzRow(tmpCustTable)" class="title">新增</el-button>
        <!-- 右边的编辑按钮 -->
        <el-button size="small" type="success" class="title" @click="submitCustForm()" width="100%">清除设置</el-button>
        <el-button size="small" type="success" class="title" @click="submitCustForm()" width="100%">保存设置</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editUpdateFormVisible" width="100%" @click="closeUpdateDialog" class="pg"
      style="padding-left: 1%; padding-right: 1%">
      <el-row>
        <el-col :span="24">
          <el-form :model="updateForm" ref="ruleForm" class="demo-ruleForm">
            <el-form-item>
              <quill-editor class="ql-editor" v-model="tmpContent" ref="myQuillEditor" :options="editorOption">
              </quill-editor>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-row :gutter="10" style="padding-bottom: 20px">
        <el-button type="success" size="small" @click="doCopyUpdateForm(updateForm.desc)">复制</el-button>
        <el-button type="success" size="small" @click="submitForm2(tmpContent)">校验</el-button>
        <el-button type="success" size="small" @click="submitUpdateForm(tmpContent)">确认</el-button>
      </el-row>
    </el-dialog>

    <el-dialog :visible.sync="editAppraiseFormVisible" @click="closeCustDialog" width="100%" class="pg">
      <el-row>
        <el-col :span="18" :offset="2" class="edit-col-first">
          <el-select size="mini" v-model="tmpCjType" placeholder="切换"
            class="fixed-width-button-large small-font-select-normal" @change="handleCjSelectionChange">
            <el-option v-for="item in custTable" :key="item.cust" :label="item.cust" :value="item.cust"
              v-if="item.type !== '客户'">
            </el-option>
          </el-select>
          <span style="margin-left: 2%">预选上家：{{ this.cjType }}</span>
        </el-col>
      </el-row>
      <el-row class="edit-col">
        <!-- <el-col :span="2">
          <el-row :gutter="10" style="margin-top: 10%">
            <el-col :span="2" class="edit-col">
              <el-button type="text" @click="changeRepoNum(5)" class="small-font-button">增加+5</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2" class="edit-col" style="margin-top: 10%">
              <el-button type="text" @click="changeRepoNum(-5)" class="small-font-button">减少-5</el-button>
            </el-col>
          </el-row>
        </el-col> -->

        <el-col class="edit-col-first">
          <el-button type="success" size="mini" @click="changeRepoNum(5)">+5</el-button>
          <el-input step="5" v-model="repoNum" :placeholder="pageInfo.ygPl" @input="showAppraiseForm"></el-input>
          <el-button type="success" size="mini" @click="changeRepoNum(-5)">-5</el-button>
        </el-col>
        <el-col class="edit-col">
          <el-input v-model="minAppraise" placeholder="设置最大亏损"></el-input>
          <el-button type="success" size="mini" @click="shareAppraise(minAppraise)">平摊风险</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :span="24">
          <!-- 扣库表 -->
          <el-table :data="appraiseTable" :header-cell-style="normalHeaderCellStyle" size="mini" stripe border
            :row-style="{ height: '5px' }" :cell-style="{ padding: '0' }" style="font-size: 8px">
            <el-table-column prop="orderId" :label="pageInfo.ygOrderId" align="center">
            </el-table-column>
            <el-table-column prop="number" :label="pageInfo.ygNumber" align="center">
            </el-table-column>
            <el-table-column prop="apprais" label="风险评估" align="center">
            </el-table-column>
            <el-table-column prop="id" label="ID" align="center"> </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <!-- <el-col :span="20" :offset="2" style="background-color: aliceblue"> -->
        <el-col :span="24">
          <pre style="
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              color: red;
              text-align: left;
              font-size: 10px;
            ">{{ updateForm.error }}</pre>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import platform from "platform";

import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import {
  getClz,
  getCust,
  getPageInfo,
  logoutReq,
  saveClz,
  saveCust,
  sendContent,
} from "../api/parseContent.js";
export default {
  components: {
    quillEditor,
  },
  name: "ParseContent",
  props: {
    msg: String,
  },
  data() {
    return {
      content: "",
      tmpContent: "",
      extractedText: "",
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: false,
        },
        placeholder: "请输入正文",
      },
      editClzFormVisible: false,
      editCustFormVisible: false,
      editUpdateFormVisible: false,
      editAppraiseFormVisible: false,
      showTotalFormVisible: false,
      num: 0,
      custShowTable: [],
      allTotalMap: new Map(),
      allCjTotalMap: new Map(),

      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        token: "",
      },
      updateForm: {
        desc: "",
        token: "",
        error: "",
      },
      orderIdName: "",
      numberName: "",
      pageInfo: {},
      isPressKcc: true,
      isReduce: true,
      lastCacheTableData: [],
      lastCacheCjKey: "",
      reduceTime: "",
      sortType: 1,
      reduceTotal: "",
      //扣库按钮
      repoNum: "",
      total: 0,
      endTime: "",
      sendResult: "",
      sendResultMsg: "识别结果展示",
      tableData: [],
      reduceTableData: [],
      cacheTime: 0,
      cacheTableData: [],
      copyRepo: "",
      cnum: 0,
      dnum: 0,
      clzCustMap: new Map(),
      clzCjMap: new Map(),
      curItem: {
        reduceTime: "",
        sortType: 1,
        reduceTotal: "",
        repoNum: "",
        total: 0,
        sendResult: "",
        sendResultMsg: "识别结果展示",
        tableData: [],
        reduceTableData: [],
        cacheTime: 0,
        cacheTableData: [],
        copyRepo: "",
      },
      clzType: "",
      customerType: "",
      cjType: " ",
      tmpClzType: "",
      tmpCustomerType: "",
      tmpCjType: "",
      isPc: false,

      clzTable: [],
      custTable: [],

      tmpClzTable: [],
      tmpCustTable: [],
      isTotalCustModel: true,
      isTotalCjModel: false,
      tmpTotalTable: [],
      editTotalFormVisible: false,

      chosenOrder: "",
      totalCust: 0,
      totalCJ: 0,
      appraiseTable: [],
      totalTable: [],
      minAppraise: "",
      loadingInstance: {},
      maxKS: "",
      maxYL: "",
      reduceTotalValue: 0,
      loading: false,
    };
  },

  created() {
    this.restoreData();
    this.createPage();
    this.getClzInfo();
    this.getCustInfo();
    this.isPc = this.checkIfPc();
  },
  methods: {
    getReduceTableData() {
      let tmp = [];
      for (let i of this.reduceTableData) {
        if (i.number > 0) {
          tmp.push(i);
        }
      }
      this.sortList2(tmp, 1);
      return tmp;
    },
    getCustTableTotal() {
      if (this.isTotalCustModel) {
        let tableData = this.caculTotalUser();
        let total = 0;
        if (tableData && tableData.length > 0) {
          for (let i of tableData) {
            total = parseInt(total) + parseInt(i.number);
          }
        }
        return total;
      } else {
        let tableData = this.tableData;
        let total = 0;
        if (tableData && tableData.length > 0) {
          for (let i of tableData) {
            total = parseInt(total) + parseInt(i.number);
          }
        }
        return total;
      }
    },
    getCustTable(sortType) {
      let res = [];
      if (this.isTotalCustModel) {
        res = this.caculTotalUser();
      } else {
        res = this.tableData;
      }
      let tmp = JSON.parse(JSON.stringify(res))
      this.sortList2(tmp, sortType);
      this.custShowTable = tmp;
      return tmp;
    },
    addPTags(str) {
      // 用换行符将字符串分割成行数组
      let lines = str.split("\n");
      // 遍历每一行，在开头和结尾添加<p>和</p>标签
      let result = lines.map((line) => `<p>${line}</p>`).join("");
      return result;
    },
    closeUpdateDialog() {
      this.tmpContent = "";
    },
    extractTagText(str) {
      // 创建一个临时的DOM元素
      let tempDiv = document.createElement("div");

      // 将HTML字符串插入到临时的DOM元素中
      tempDiv.innerHTML = str;

      // 使用textContent属性提取文本，并保留换行符
      return tempDiv.textContent.trim();
    },
    highlightText(searchStringArr, content) {
      // 将HTML字符串插入到临时的DOM元素中
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.extractedText;

      // 分割成每行文本
      let lines = this.extractedText.split("\n").filter((line) => line.trim() !== "");
      console.log("lines");
      console.log(lines);

      // 替换匹配的行
      for (let i = 0; i < lines.length; i++) {
        let flag = false;
        for (let searchString of searchStringArr) {
          if (lines[i].includes(searchString)) {
            flag = true;
          }
        }
        if (flag) {
          lines[i] = `<p><span style="color: rgb(255, 0, 0);">${lines[i]}</span></p>`;
        } else {
          lines[i] = `<p>${lines[i]}</p>`;
        }
      }
      // 更新highlightedContent
      content = lines.join("");
      console.log("after highlight: " + content);
      return content;
    },
    extractText(content) {
      // 创建一个临时的DOM元素
      let tempDiv = document.createElement("div");

      // 将HTML字符串插入到临时的DOM元素中
      tempDiv.innerHTML = content;

      // 使用textContent属性提取文本
      return tempDiv.innerHTML
        .replace(/<\/p>/g, "\n") // 将每个结束段落标签替换为换行符
        .replace(/<br\s*\/?>/gi, "\n") // 将每个换行标签替换为换行符
        .replace(/<[^>]+>/g, "") // 去除所有HTML标签
        .replaceAll('&nbsp;', " ") // 去除所有HTML标签
        .trim();
    },
    changeRepoNum(val) {
      this.repoNum = parseInt(this.repoNum) + parseInt(val);
      this.showAppraiseForm(this.repoNum);
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "计算中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log("已经启动加载");
    },
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
      console.log("已经关闭加载");
    },
    shareAppraise(target) {
      this.showLoading();
      this.loading = true;
      setTimeout(() => {
        let i = 0;
        while (true) {
          let res = this.caculAppraise(i);
          if (res === undefined || res === null || res.appraiseTable.length <= 0) {
            this.hideLoading();
            this.repoNum = '';
            this.appraiseTable = [];
            return;
          }
          let minAppraise = res.minAppraise;
          if (minAppraise > target) {
            console.log("minAppraise:" + minAppraise + "  i:" + i);
            break;
          }
          i++;
        }
        i--;
        this.showAppraiseForm(i);
        this.repoNum = i;
        this.loading = false;
        this.hideLoading();
      }, 30);
    },
    showTotalForm() {
      this.showTotalFormVisible = true;
    },
    //当前上家的总计和订单id，需要减去已存储的订单
    showAppraiseForm(repoNum) {
      this.editAppraiseFormVisible = true;
      // if(repoNum === null || repoNum === undefined || repoNum <= 0) {
      //   return;
      // }
      let res = this.caculAppraise(repoNum);
      let appraiseTable = res.appraiseTable;
      appraiseTable.sort((a, b) => {
        return parseInt(a.apprais) - parseInt(b.apprais);
      });
      // console.log("排序");
      // console.log(JSON.stringify(appraiseTable));
      let maxKS = Number.MAX_SAFE_INTEGER;
      let maxYL = Number.MIN_SAFE_INTEGER;
      for (let i in appraiseTable) {
        if (parseInt(appraiseTable[i].apprais) < maxKS) {
          maxKS = appraiseTable[i].apprais;
        }
        if (parseInt(appraiseTable[i].apprais) > maxYL) {
          maxYL = appraiseTable[i].apprais;
        }
        appraiseTable[i].id = parseInt(i) + 1;
        appraiseTable[i].apprais = parseInt(appraiseTable[i].apprais);
      }
      this.maxKS = parseInt(maxKS).toFixed(0);
      this.maxYL = parseInt(maxYL).toFixed(0);
      this.appraiseTable = appraiseTable;
      this.minAppraise = res.minAppraise;
    },

    caculAppraise(repoNum) {
      let appraiseTable = [];
      let minAppraise = "";
      if (repoNum === undefined) {
        repoNum = 0;
      }
      let tmpcust = this.caculTotalUser();
       console.log("用户数据：" + JSON.stringify(tmpcust));
      let curCjTable = this.calculReduceRepo(repoNum);
       console.log("扣除后的上家数据：" + JSON.stringify(curCjTable));
      let curCjCaculTable = this.caculTotalCj(curCjTable);
       console.log("统计后的上家数据：" + JSON.stringify(curCjCaculTable));
      if (curCjCaculTable === null || curCjCaculTable === undefined || curCjCaculTable.length <= 0) {
        return { minAppraise: '', appraiseTable: [] };
      }

      let keyToconfig = new Map();
      for (let cItem of this.custTable) {
        let key = cItem.clz + "__" + cItem.cust;
        keyToconfig.set(key, cItem);
      }
      let min = Number.MAX_SAFE_INTEGER;

      for (let i of tmpcust) {
        // if (i.number > repoNum) {
        //   i.number = repoNum;
        // }

        let custTotal = 0;
        let zTotal = 0;
        for (let key of this.allTotalMap.keys()) {
          let tmpTotal = this.allTotalMap.get(key);
          if (tmpTotal === undefined || keyToconfig.get(key) === undefined) {
             console.log("zTotal undefined:" + key);
            continue;
          }
          custTotal += tmpTotal;
          zTotal += (tmpTotal * keyToconfig.get(key).z) / 100;
        }
        let gTotal = 0;
        i.number = 0;
        let gTotalCacul = "(";
        let count = 0;
        for (let key of this.clzCustMap.keys()) {
          let str = this.clzCustMap.get(key);
          if (str === undefined || keyToconfig.get(key) === undefined) {
             console.log("gtotal undefined:" + key);
            continue;
          }
          let item = JSON.parse(str);
          let tableData = item.tableData;
          for (let j of tableData) {
            if (j.orderId === i.orderId) {
              gTotal += j.number * keyToconfig.get(key).g;
              if (count++ === 0) {
                gTotalCacul = gTotalCacul + " " + j.number + "*" + keyToconfig.get(key).g;
              } else {
                gTotalCacul =
                  gTotalCacul + " + " + j.number + "*" + keyToconfig.get(key).g;
              }
              i.number = parseInt(i.number) + parseInt(j.number);
              break;
            }
          }
        }
        gTotalCacul = gTotalCacul + ") ";

        let cjTotal = 0;
        let cjZTotal = 0;
        let cjZTotalCacul = " (";
        count = 0;
         console.log("上家total" + JSON.stringify(this.allCjTotalMap));
        for (let key of this.allCjTotalMap.keys()) {
          let tmpTotal = this.allCjTotalMap.get(key);
          cjTotal += tmpTotal;
          if (keyToconfig.get(key)) {
            cjZTotal += (tmpTotal * keyToconfig.get(key).z) / 100;
            if (count++ === 0) {
              cjZTotalCacul =
                cjZTotalCacul + "" + tmpTotal + "*" + keyToconfig.get(key).z + "%";
            } else {
              cjZTotalCacul =
                cjZTotalCacul + " + " + tmpTotal + "*" + keyToconfig.get(key).z + "%";
            }
          }
        }
        cjZTotalCacul = cjZTotalCacul + ")";
        let cjGTotal = 0;
        let cjGTotalCacul = "(";
        count = 0;
         console.log("上家keys:" + JSON.stringify(this.clzCjMap));
        for (let key of this.clzCjMap.keys()) {
          // console.log("cjkey:" + key);
          let str = this.clzCjMap.get(key);
          if (keyToconfig.get(key) === undefined) {
            // console.log("不包含上家" + key);
            continue;
          }
          let item = JSON.parse(str);
          let tableData = item.cacheTableData;
          //减去已存储的数量
          let tmpkey = this.clzType + "__" + this.cjType;
          for (let j of tableData) {
            if (j.orderId === i.orderId && tmpkey !== key) {
               console.log(i.orderId + "删除j:" + j.number);
              i.number -= j.number;
              break;
            }
          }
           console.log("使用当前计算：" + tmpkey + " 遍历key: " + key);
          if (tmpkey === key) {
            tableData = curCjCaculTable;
             console.log("使用当前计算table：" + JSON.stringify(tableData));
            for (let j of tableData) {
              if (j.orderId === i.orderId) {
                 console.log(i.orderId + "删除j:" + j.number);
                i.number -= j.number;
                break;
              }
            }
          }
          for (let j of tableData) {
            if (j.orderId === i.orderId) {
              cjGTotal += j.number * keyToconfig.get(key).g;
               console.log(i.orderId + "删除j:" + j.number);
              if (count++ === 0) {
                cjGTotalCacul =
                  cjGTotalCacul + "" + j.number + "*" + keyToconfig.get(key).g;
              } else {
                cjGTotalCacul =
                  cjGTotalCacul + "+" + j.number + "*" + keyToconfig.get(key).g;
              }
              break;
            }
          }
        }
        cjGTotalCacul = cjGTotalCacul + ")";

        i.custTotal = custTotal;
        i.zTotal = zTotal;
        i.gTotal = gTotal;
        i.cjGTotal = cjGTotal;
        i.cjTotal = cjTotal;
        i.cjZTotal = cjZTotal;
        i.apprais = custTotal - zTotal - gTotal + cjGTotal - (cjTotal - cjZTotal);
        console.log(
   i.orderId +
   "  " +
   custTotal +
   "-" +
   zTotal +
   "-" +
   gTotalCacul +
   "+" +
   cjGTotalCacul +
   "-(" +
   cjTotal +
   "-" +
   cjZTotalCacul +
   ") = " +
   i.apprais
 );
        if (i.apprais < min) {
          min = i.apprais;
        }
        i.apprais = i.apprais.toFixed(2);
        appraiseTable.push(i);
      }
      if (min < 0) {
        minAppraise = -min;
        minAppraise = parseInt(minAppraise);
      } else {
        minAppraise = min;
        minAppraise = parseInt(minAppraise);
      }
      return { minAppraise: minAppraise, appraiseTable: appraiseTable };
    },
    //通过风险平衡值，反查扣库值
    avgAppraise() {
      let minItem = {};
      let min = Number.MAX_SAFE_INTEGER;
      for (let item of this.appraiseTable) {
        if (Number(item.apprais) < min) {
          minItem = item;
        }
      }
      let val = minItem.apprais - (custTotal - zTotal - gTotal);
    },
    showTotal() {
      // console.log("chose order:" + this.chosenOrder);
      this.saveCjItem();
      this.saveCustItem();
      if (this.chosenOrder && this.chosenOrder !== "") {
        let tmpCjNum = 0;
        let tmpCustNum = 0;
        let tmpCustTotalU = 0;
        let tmpCustTotalV = 0;
        let tmpCustTotalW = 0;
        let tmpCustTotalM = 0;
        let tmpCustTotalN = 0;
        let tmpCjTotalU = 0;
        let tmpCjTotalV = 0;
        let tmpCjTotalW = 0;
        let tmpCjTotalM = 0;
        let tmpCjTotalN = 0;
        let tmpArr = [];
        let tmpArr2 = [];
        let allInOneU = 0;
        for (let item of this.custTable) {
          if (item.clz !== this.clzType) {
            continue;
          }
          let key = this.clzType + "__" + item.cust;
          if (item.type === "客户") {
            tmpCustNum += 1;
            let i = JSON.parse(JSON.stringify(item));
            i.u = 0;
            i.v = 0;
            i.w = 0;
            i.m = 0;
            i.n = 0;
            let str = this.clzCustMap.get(key);
            // console.log(str);
            if (str !== undefined) {
              let item = JSON.parse(str);
              if (item.tableData !== undefined) {
                for (let j of item.tableData) {
                  if (j.orderId.split('(')[0] === this.chosenOrder) {
                    i.u = j.number;
                    tmpCustTotalU += parseInt(j.number);
                  }
                  i.v += parseInt(j.number);
                  tmpCustTotalV += parseInt(j.number);
                }
              }
            }
            allInOneU = parseFloat(allInOneU) + parseFloat(i.v) - parseFloat(i.v * item.z / 100) - (i.u * item.g);
            i.w = i.u * item.g - (i.v - i.v * item.z / 100);
            i.w = i.w.toFixed(2);
            i.m = (i.v * item.z / 100).toFixed(2);
            tmpCustTotalM += parseFloat(i.m);
            i.n = i.v - i.m;
            tmpCustTotalN += parseFloat(i.n);
            tmpCustTotalW = parseFloat(tmpCustTotalW) + parseFloat(i.w);
            tmpArr.push(i);
          } else {
            tmpCjNum += 1;
            let i = JSON.parse(JSON.stringify(item));
            i.u = 0;
            i.v = 0;
            i.w = 3;
            i.m = 0;
            i.n = 0;
            let str = this.clzCjMap.get(key);
            // console.log(str);
            if (str !== undefined) {
              let item = JSON.parse(str);
              if (item.cacheTableData !== undefined) {
                for (let j of item.cacheTableData) {
                  if (j.orderId.split('(')[0] === this.chosenOrder) {
                    i.u = j.number;
                    tmpCjTotalU += parseInt(j.number);
                  }
                  i.v += parseInt(j.number);
                  tmpCjTotalV += parseInt(j.number);
                }
              }
            }
            i.w = (i.v - (i.v * item.z / 100)) - (i.u * item.g);
            i.m = (i.v * item.z / 100).toFixed(2);
            tmpCjTotalM += parseFloat(i.m);
            i.n = i.v - i.m;
            tmpCjTotalN += parseFloat(i.n);
            // console.log("(" + i.u + "-" + i.u + "*" + item.z + ") - " + i.v + "*" + item.g);
            allInOneU = parseFloat(allInOneU) - parseFloat(i.v) + parseFloat(i.v * item.z / 100) + (i.u * item.g);
            i.w = i.w.toFixed(2);
            tmpCjTotalW = parseFloat(tmpCjTotalW) + parseFloat(i.w);
            tmpArr2.push(i);
          }
        }

        tmpArr.push({ type: '客户', u: tmpCustTotalU, v: tmpCustTotalV, w: tmpCustTotalW.toFixed(2), cust: '总计', m: tmpCustTotalM.toFixed(2), n: tmpCustTotalN.toFixed(2) });
        tmpArr2.push({ type: '上家', u: tmpCjTotalU, v: tmpCjTotalV, w: tmpCjTotalW.toFixed(2), cust: '总计', m: tmpCjTotalM.toFixed(2), n: tmpCjTotalN.toFixed(2) });
        for (let item of tmpArr2) {
          tmpArr.push(item);
        }
        //[1640-(1100*3%+540*0%）]-（140*20+60*21）+[（40*21）+（10*22）+（20*3）]-[350-(200*3%+50*0%+100*4%)]
        let tmpM = parseFloat(tmpCustTotalM) - parseFloat(tmpCjTotalM);
        let tmpN = parseFloat(tmpCustTotalN) - parseFloat(tmpCjTotalN);
        tmpArr.push({ type: '个人汇总', u: tmpCustTotalU - tmpCjTotalU, v: tmpCustTotalV - tmpCjTotalV, w: allInOneU.toFixed(2), cust: '个人汇总', m: tmpM.toFixed(2), n: tmpN.toFixed(2) });
        // console.log("total");
        // console.log(JSON.stringisfy(tmpArr));
        this.totalTable = tmpArr;
        this.totalCJ = tmpCjNum + 1;
        this.totalCust = tmpCustNum + 1;
      }
    },
    copyToRuleForm() {
      this.ruleForm.desc = this.updateForm.desc;
      this.updateForm.desc = "";
      this.updateForm.error = "";
      this.editUpdateFormVisible = false;
    },
    normalHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      return { fontSize: "8px", background: "#67c23a", color: "white" };
    },
    biggerHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      return { fontSize: "12px", background: "#67c23a", color: "white" };
    },
    getHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      if (true) {
        return {
          // fontSize: "16px",
          background: "#67c23a",
          color: "white",
          padding: "0%",
          margin: "0%",
        };
      }
      if (rowIndex === 0) {
        if (columnIndex == 1 && rowIndex == 0) {
          return {
            fontSize: "6px",
            background: "#dfeed4",
            color: "black",
            padding: "0%",
            margin: "0%",
            borderTop: "0.1px solid rgb(58, 57, 57)",
          };
        }
        if (columnIndex <= 1 && rowIndex <= 1) {
          return {
            fontSize: "6px",
            background: "#cbf0ff",
            color: "black",
            padding: "0%",
            margin: "0%",
            borderTop: "0.1px solid rgb(58, 57, 57)",
          };
        }

        if (columnIndex === 2) {
          return {
            fontSize: "6px",
            background: "#ffecd4",
            color: "black",
            padding: "0%",
            margin: "0%",
            borderTop: "0.1px solid rgb(58, 57, 57)",
          };
        }
        if (columnIndex === 3) {
          return {
            fontSize: "6px",
            background: "#dfeed4",
            color: "black",
            padding: "0%",
            margin: "0%",
            borderTop: "0.1px solid rgb(58, 57, 57)",
          };
        }

        return {
          fontSize: "6px",
          background: "#67c23a",
          color: "white",
          padding: "0%",
          margin: "0%",
          borderTop: "0.1px solid rgb(58, 57, 57)",
        };
      }
      if (columnIndex == 1 && rowIndex == 0) {
        return {
          fontSize: "6px",
          background: "#dfeed4",
          color: "black",
          padding: "0%",
          margin: "0%",
        };
      }
      if (columnIndex <= 1 && rowIndex <= 1) {
        return {
          fontSize: "6px",
          background: "#cbf0ff",
          color: "black",
          padding: "0%",
          margin: "0%",
        };
      }

      if (columnIndex === 2) {
        return {
          fontSize: "6px",
          background: "#ffecd4",
          color: "black",
          padding: "0%",
          margin: "0%",
        };
      }
      if (columnIndex === 3) {
        return {
          fontSize: "6px",
          background: "#dfeed4",
          color: "black",
          padding: "0%",
          margin: "0%",
        };
      }

      return {
        fontSize: "6px",
        background: "#67c23a",
        color: "white",
        padding: "0%",
        margin: "0%",
      };
    },
    getCellStyle({ row, column, rowIndex, columnIndex }) {
      const baseStyle = {
        // fontSize: "6px",
        color: "black",
        padding: "0%",
        margin: "0%",
        // textAlign: "right",
        // alignItems: "left",
        paddingLeft: "0px",
        borderTop: "0.1px solid rgb(58, 57, 57)"
      };

      const styles = {
        default: {
          ...baseStyle,
          background: "#67c23a",
          color: "white"
        },
        common: {
          ...baseStyle,
          background: "#cbf0ff"
        },
        green: {
          ...baseStyle,
          background: "#dfeed4"
        },
        yellow: {
          ...baseStyle,
          background: "#ffecd4"
        }
      };
      if (rowIndex === (this.totalCust + this.totalCJ)) {
        return styles.common;
      }

      if (rowIndex === this.totalCust || rowIndex === 0) {
        if (columnIndex <= 1) {
          return styles.common;
        }
        if (columnIndex === 2 || columnIndex === 4) {
          return styles.green;
        }
        if (columnIndex >= 3) {
          if (columnIndex % 2 === 1) {
            return styles.yellow;
          } else {
            return styles.green;
          }
        }
        return styles.common;
      }

      if (columnIndex <= 1) {
        return styles.common;
      }
      if (columnIndex === 2 || columnIndex === 4) {
        return styles.green;
      }
      if (columnIndex >= 3) {
        if (columnIndex % 2 === 1) {
          return styles.yellow;
        } else {
          return styles.green;
        }
      }

      return styles.default;
    },
    showUpdateForm() {
      // this.content = this.addPTags(this.ruleForm.desc);
      this.tmpContent = this.content;
      this.submitForm2(this.tmpContent);
      this.editUpdateFormVisible = true;
    },
    caculTotalUser() {
      if (this.customerType) {
        this.saveCustItem();
      }
      let table = [];
      this.allTotalMap.clear();
      for (let key of this.clzCustMap.keys()) {
        if (key.startsWith(this.clzType + "__")) {
          let tmpItem = JSON.parse(this.clzCustMap.get(key));
          let tmpArr = [];
          if (table.length > 0) {
            for (let i of tmpItem.tableData) {
              let flag = false;
              for (let j of table) {
                if (i.orderId == j.orderId) {
                  j.number = parseInt(i.number) + parseInt(j.number);
                  flag = true;
                }
              }
              if (!flag) {
                tmpArr.push(i);
              }
            }
            for (let obj of tmpArr) {
              table.push(obj);
            }
          } else {
            table = tmpItem.tableData;
          }
          let tmpTotal = 0;
          for (let i of tmpItem.tableData) {
            tmpTotal = tmpTotal + parseInt(i.number);
          }
          this.allTotalMap.set(key, tmpTotal);
          for (let obj of tmpArr) {
            table.push(JSON.parse(JSON.stringify(obj)));
          }
        }
      }
      return table;
    },
    caculTotalCj(curTable) {
      if (this.clzType) {
        this.saveCjItem();
      }
      this.allCjTotalMap.clear();
      let curKey = this.clzType + "__" + this.cjType;
      for (let key of this.clzCjMap.keys()) {
        if (key.startsWith(this.clzType + "__")) {
          let isCur = false;
          if (key === curKey) {
            isCur = true;
          }
          let tmpTotal = 0;
          let tmpItem = JSON.parse(this.clzCjMap.get(key));
          if (isCur) {
            //需要减去已存储的订单
            let zeroCount = 0;
            for (let i of curTable) {
              for (let k of this.clzCjMap.keys()) {
                if (!k.startsWith(this.clzType + "__")) {
                  continue;
                }
                let newIt = JSON.parse(this.clzCjMap.get(k));
                for (let ii of newIt.cacheTableData) {
                  if (ii.orderId === i.orderId) {
                    // console.log(
                    //   i.orderId + ":" + i.number + "减去已经存储的" + k + ii.number
                    // );
                    i.number = parseInt(i.number) - parseInt(ii.number);
                    i.actulaNum = i.number;
                    break;
                  }
                }
              }
              let n = parseInt(i.number);
              if (n < 0) {
                // console.log("超过阈值");
                zeroCount++;
                i.number = 0;
                n = 0;
                if (zeroCount == curTable.length)
                  return null;
              }
              tmpTotal = tmpTotal + parseInt(n);
            }
            for (let j of tmpItem.cacheTableData) {
              tmpTotal = tmpTotal + parseInt(j.number);
              for (let i of curTable) {
                if (i.orderId === j.orderId) {
                  i.number = parseInt(i.number) + parseInt(j.number);
                  break;
                }
              }
            }
            // console.log("当前上家:" + tmpTotal);
            this.allCjTotalMap.set(key, tmpTotal);
          } else {
            // console.log("cachetable:" + key);
            // console.log(tmpItem.cacheTableData);
            for (let i of tmpItem.cacheTableData) {
              tmpTotal = tmpTotal + parseInt(i.number);
            }
          }
          this.allCjTotalMap.set(key, tmpTotal);
        }
      }
      // console.log("cacul table:" + caculTable);
      return curTable;
    },
    undoSaveCache() {
      // console.log("撤回存储：" + this.lastCacheCjKey);
      if (this.lastCacheCjKey !== undefined && this.lastCacheCjKey !== "") {
        this.isReduce = false;
        this.isPressKcc = false;
        let item = JSON.parse(this.clzCjMap.get(this.lastCacheCjKey));
        // console.log("当前存储：" + JSON.stringify(this.clzCjMap.get(this.lastCacheCjKey)));
        item.cacheTableData = this.lastCacheTableData;
        // console.log("还原存储：" + JSON.stringify(this.lastCacheTableData));
        this.clzCjMap.set(this.lastCacheCjKey, JSON.stringify(item));
        this.getCjItem();
        this.lastCacheCjKey = "";
        this.lastCacheTableData = [];
        this.cacheTime = parseInt(this.cacheTime) - 1;
      }
    },
    reduceCacheRepo() {
      this.isPressKcc = true;
      this.saveCjItem();
      for (let i of this.reduceTableData) {
        for (let newKey of this.clzCjMap.keys()) {
          if (!newKey.startsWith(this.clzType + "__")) {
            continue;
          }
          let cacheItem = JSON.parse(this.clzCjMap.get(newKey));
          for (let j of cacheItem.cacheTableData) {
            if (j.orderId === i.orderId) {
              i.number = parseInt(i.number) - parseInt(j.number);
              break;
            }
          }
        }
      }

      let tmpArr = [];
      for (const iterator of this.reduceTableData) {
        if (iterator.number > 0) {
          tmpArr.push(iterator);
        }
      }
      this.reduceTableData = tmpArr;
    },
    changeToTotal() {
      this.isTotalCustModel = this.isTotalCustModel ? false : true;
    },
    isSelectCust() {
      return (
        this.customerType === undefined ||
        this.customerType === null ||
        this.customerType === ""
      );
    },
    getcnum() {
      if (this.isTotalCustModel) {
        let finalResult = 0;
        let dfinal = 0;
        for (let item of this.custTable) {
          let key = item.clz + "__" + item.cust;
          if (item.clz === this.clzType && this.clzCustMap.has(key)) {
            let tmp = JSON.parse(this.clzCustMap.get(key));
            let z = item.z;
            let total = tmp.total;
            let result = (total * z) / 100;
            let d = total - result;
            finalResult += result;
            dfinal += d;
          }
        }
        this.dnum = dfinal.toFixed(2);
        return finalResult.toFixed(2);
      } else {
        for (let item of this.custTable) {
          if (item.clz === this.clzType && item.cust === this.customerType) {
            let z = item.z;
            let result = (this.total * z) / 100;
            this.dnum = (this.total - result).toFixed(2);
            return result.toFixed(2);
          }
        }
      }
      return 0;
    },
    getCustItem() {
      this.curItem = {
        sortType: 1,
        total: 0,
        sendResult: "",
        sendResultMsg: "识别结果展示",
        tableData: [],
      };
      if (this.clzType && this.customerType) {
        let key = this.clzType + "__" + this.customerType;
        let val = this.clzCustMap.get(key);
        // console.log("get cur item" + key + " " + val);
        if (val) {
          this.curItem = JSON.parse(val);
        }
      }

      this.sortType = this.curItem.sortType;
      this.total = this.curItem.total;
      this.sendResult = this.curItem.sendResult;
      this.sendResultMsg = this.curItem.sendResultMsg;
      this.tableData = this.curItem.tableData;
      this.content = this.curItem.content;
      if (this.content === undefined) {
        this.content = "";
      }
    },
    getCjItem() {
      this.curItem = {
        reduceTime: "",
        reduceTotal: "",
        repoNum: "",
        reduceTableData: [],
        cacheTableData: [],
      };
      if (this.clzType && this.cjType) {
        let key = this.clzType + "__" + this.cjType;
        let val = this.clzCjMap.get(key);
        // console.log("get cur item" + key + " " + val);
        if (val) {
          this.curItem = JSON.parse(val);
        }
      }

      this.reduceTime = this.curItem.reduceTime;
      this.reduceTotal = this.curItem.reduceTotal;
      this.repoNum = this.curItem.repoNum;
      this.reduceTableData = this.curItem.reduceTableData;
      this.cacheTableData = this.curItem.cacheTableData;
    },
    saveCjItem() {
      if (
        this.clzType !== undefined &&
        this.clzType !== "" &&
        this.cjType !== undefined &&
        this.cjType !== ""
      ) {
        let key = this.clzType + "__" + this.cjType;
        // console.log("save cjType item" + key);

        let tmp = {};
        tmp.reduceTime = this.reduceTime;
        tmp.reduceTotal = this.reduceTotal;
        tmp.reduceTableData = this.reduceTableData;
        tmp.cacheTableData = this.cacheTableData;
        //console.log(tmp);
        this.clzCjMap.set(key, JSON.stringify(tmp));
        //console.log("当前cjkeys:" + JSON.stringify(this.clzCjMap.keys()));
      }
    },
    clearCurItem() {
      if (this.isTotalCustModel) {
        this.clzCustMap.clear();
        return;
      }
      let key = this.clzType + "__" + this.customerType;
      this.clzCustMap.delete(key);
    },
    saveCustItem() {
      if (
        this.clzType !== undefined &&
        this.clzType !== "" &&
        this.customerType !== undefined &&
        this.customerType !== ""
      ) {
        let key = this.clzType + "__" + this.customerType;
        // console.log("save cur item" + key);

        let tmp = {};

        tmp.sortType = this.sortType;
        tmp.content = this.content;
        tmp.total = this.total;
        tmp.sendResult = this.sendResult;
        tmp.sendResultMsg = this.sendResultMsg;
        tmp.tableData = this.tableData;

        this.clzCustMap.set(key, JSON.stringify(tmp));
        this.curItem = {};
      }
    },
    closeCustDialog() { },
    closeClzDialog() {
      //console.log("close");
      this.editClzFormVisible = false;
      this.showTotalFormVisible = false;
      let flag = false;
      for (let item of this.clzTable) {
        //console.log(item);
        //console.log("equals " + this.clzType + " " + item.clz);
        if (this.clzType === item.clz) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.clearCurItem();
        this.clzType = "";
        this.customerType = "";
        this.tmpCustTable = [];
      }
    },
    getClzInfo() {
      let token = localStorage.getItem("token");
      getClz({ token: token }).then((res) => {
        // console.log("clz");
        // console.log(res.data);
        if (res.code == 999) {
          this.$message({
            type: "info",
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        this.clzTable = res.data;
      });
    },
    getCustInfo() {
      let token = localStorage.getItem("token");
      getCust({ token: token, clz: this.clzType }).then((res) => {
        // console.log("cust");
        // console.log(res.data);
        if (res.code == 999) {
          this.$message({
            type: "info",
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        this.custTable = [];
        for (let item of res.data) {
          if (this.clzType === item.clz) {
            this.custTable.push(item);
          }
        }
        // console.log(this.custTable);
      });
    },
    submitClzForm() {
      let token = localStorage.getItem("token");
      saveClz({
        token: token,
        userClzs: this.tmpClzTable,
      }).then((res) => {
        //console.log("saveclz");
        //console.log(res);
        if (res.data.code == 999) {
          this.$message({
            type: "info",
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        if (res.data.code == 0) {
          this.$message({
            type: "info",
            message: "保存成功",
          });
          this.getClzInfo();
          this.editClzFormVisible = false;
        }
      });
    },
    submitCustForm() {
      let token = localStorage.getItem("token");
      saveCust({
        token: token,
        clz: this.clzType,
        userClzs: this.tmpCustTable,
      }).then((res) => {
        //console.log("saveclz");
        //console.log(res);
        if (res.data.code == 999) {
          this.$message({
            type: "info",
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        this.$message({
          type: "info",
          message: "保存成功",
        });
        this.getCustInfo();
        this.editCustFormVisible = false;
      });
    },
    isClzDisable(value) {
      return value !== null && value !== undefined && value !== "";
    },
    showClzEditForm() {
      this.editClzFormVisible = true;
      this.tmpClzTable = JSON.parse(JSON.stringify(this.clzTable));
    },
    addClzRow(rows) {
      rows.push({});
    },
    showCustEditForm() {
      this.editCustFormVisible = true;
      this.tmpCustTable = JSON.parse(JSON.stringify(this.custTable));
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    checkIfPc() {
      // console.log(platform.os.family);
      let res = platform.os.family !== "iOS" && platform.os.family !== "Android";
      // console.log("check is pc:" + res);
      return res;
    },
    selectClass() {
      // 根据设备类型动态地返回不同的 class
      // console.log("ispc " + this.isPc);
      let res = this.isPc == true ? "pc-select" : "mobile-select";
      // console.log(res);
      return res;
    },
    selectDialogClass() {
      let res = this.isPc == true ? "pc-select" : "mobile-dialog";
      return res;
    },
    handleSelectionChange(value) {
      this.saveCustItem();
      this.clzType = value;
      this.tmpClzType = "";
      this.customerType = "";
      this.tmpCustomerType = "";
      let tmp = [];
      this.getCustInfo();
      this.getCustItem();

      // 在这里处理选择事件，比如更新数据或触发其他逻辑
    },
    handleCustomerSelectionChange(value) {
      this.saveCustItem();
      this.customerType = value;
      this.getCustItem();
      this.tmpCustomerType = "";
      // 在这里处理选择事件，比如更新数据或触发其他逻辑
    },
    handleCjSelectionChange(value) {
      this.saveCjItem();
      this.cjType = value;
      this.getCjItem();
      this.tmpCjType = "";
      this.isTotalCjModel = false;
      // 在这里处理选择事件，比如更新数据或触发其他逻辑
    },
    createPage() {
      this.endTime = localStorage.getItem("endTime");
      getPageInfo().then((res) => {
        // console.log(res.data);
        this.pageInfo = res.data.data;
        this.orderIdName = res.data.data.orderIdName;
        this.numberName = res.data.data.numberName;
      });
    },
    logout() {
      logoutReq(localStorage.getItem("token"));
      localStorage.clear();
      this.clzCustMap.clear();
      this.clzCjMap.clear();
      this.$message({
        type: "info",
        message: "已退出登录",
      });
      this.$router.push("/"); //登录验证成功路由实现跳转
    },
    changeSort() {
      this.sortType = (this.sortType + 1) % 2;
      this.sortList2(this.custShowTable, this.sortType);
      // sortList2();
    },
    sortList() {
      if (this.sortType == 1) {
        this.tableData.sort((a, b) => {
          if (a.number == 0 && b.number == 0) {
            return (
              a.orderId.slice(0, a.orderId.indexOf("(")) -
              b.orderId.slice(0, b.orderId.indexOf("("))
            );
          }
          return b.number - a.number;
        });
      } else {
        this.tableData.sort((a, b) => {
          if (a.number == 0 && b.number != 0) {
            return 1;
          }
          if (b.number == 0 && a.number != 0) {
            return -1;
          }
          return (
            a.orderId.slice(0, a.orderId.indexOf("(")) -
            b.orderId.slice(0, b.orderId.indexOf("("))
          );
        });
      }
    },
    sortList2(tableData, sortType) {
      if (sortType == 1) {
        tableData.sort((a, b) => {
          if (a.number == 0 && b.number == 0) {
            return (
              a.orderId.slice(0, a.orderId.indexOf("(")) -
              b.orderId.slice(0, b.orderId.indexOf("("))
            );
          }
          return b.number - a.number;
        });
      } else {
        tableData.sort((a, b) => {
          if (a.number == 0 && b.number != 0) {
            return 1;
          }
          if (b.number == 0 && a.number != 0) {
            return -1;
          }
          return (
            a.orderId.slice(0, a.orderId.indexOf("(")) -
            b.orderId.slice(0, b.orderId.indexOf("("))
          );
        });
      }
    },
    mafan() {
      let tmpArr = this.getTmpArr();
      let sum = 0;
      for (const i of this.tableData) {
        let tmp = parseInt(i.number);
        for (const j of tmpArr) {
          if (i.orderId == j.orderId) {
            let diff = parseInt(i.number) - parseInt(j.number);
            if (diff > 0) {
              tmp = diff;
            }
          }
        }
        sum = sum + tmp;
      }
      return sum;
    },
    totalVal() {
      //console.log("计算总数");
      this.saveCjItem();
      let total = 0;
      for (let key of this.clzCjMap.keys()) {
        if (key.startsWith(this.clzType)) {
          let item = JSON.parse(this.clzCjMap.get(key));
          let table = item.cacheTableData;
          for (let i of table) {
            total += i.number;
          }
        }
      }
      // let reduceTotalValue = total;
      // this.getCustTable();
      //console.log("存储总数：" + total);
      let curCjTable = this.calculReduceRepo(this.repoNum);
      //console.log("扣除repo后的上家数据：" + JSON.stringify(curCjTable));
      // let curCjCaculTable = this.caculTotalCj(curCjTable);
      for (let key of this.clzCjMap.keys()) {
        if (key.startsWith(this.clzType)) {
          let item = JSON.parse(this.clzCjMap.get(key));
          let table = item.cacheTableData;
          for (let i of table) {
            for (let j of curCjTable) {
              if (i.orderId === j.orderId) {
                j.number = parseInt(j.number) - parseInt(i.number);
                if (j.number < 0) {
                  j.number = 0;
                }
                break;
              }
            }
          }
        }
      }
      //console.log("扣除存储后的上家数据：" + JSON.stringify(curCjTable));

      for (let j of curCjTable) {
        total += j.number;
      }
      //console.log("添加后总数：" + total);
      this.reduceTotalValue = parseInt(this.getCustTableTotal()) - parseInt(total);
      return total;
    },
    getTmpArr() {
      let tmpArr = [];
      if (this.tableData.length <= 0) {
        return tmpArr;
      }
      if (this.reduceTableData.length <= 0) {
        tmpArr = this.cacheTableData;
      } else {
        if (this.cacheTableData.length <= 0) {
          tmpArr = this.reduceTableData;
        } else {
          for (const i of this.reduceTableData) {
            let existFlag = false;
            for (const j of this.cacheTableData) {
              if (i.orderId == j.orderId) {
                existFlag = true;
                let diff = parseInt(i.number) - parseInt(j.number);
                if (diff >= 0) {
                  let newobj = {};
                  newobj.orderId = i.orderId;
                  newobj.number = diff;
                  tmpArr.push(newobj);
                }
              }
            }
            if (!existFlag) {
              tmpArr.push(i);
            }
          }
          let newArr = [];
          for (const i of tmpArr) {
            let num = i.number;
            for (const j of this.cacheTableData) {
              if (i.orderId == j.orderId) {
                num = parseInt(j.number) + parseInt(i.number);
              }
            }
            let newo = {};
            newo.orderId = i.orderId;
            newo.number = num;
            newArr.push(newo);
          }
          for (const iterator of this.cacheTableData) {
            let existFlag = false;
            for (const j of newArr) {
              if (iterator.orderId == j.orderId) {
                existFlag = true;
              }
            }
            if (!existFlag) {
              let newo = {};
              newo.orderId = iterator.orderId;
              newo.number = iterator.number;
              newArr.push(newo);
            }
          }
          tmpArr = newArr;
        }
      }
      return tmpArr;
    },

    mafan1() {
      let tmpArr = this.getTmpArr();
      // console.log(tmpArr);
      let sum = 0;
      for (const iterator of tmpArr) {
        sum += iterator.number;
      }
      return sum;
    },
    caculLeft() {
      if (this.reduceTableData.length <= 0) {
        return 0;
      }
      let sum = 0;
      for (const iterator of this.reduceTableData) {
        sum = sum + parseInt(iterator.number);
      }
      return sum;
    },
    caculCache() {
      if (this.cacheTableData.length <= 0) {
        return 0;
      }
      let sum = 0;
      for (const iterator of this.cacheTableData) {
        sum = sum + parseInt(iterator.number);
      }
      return sum;
    },
    isShow() {
      return this.isPressKcc;
      // if (this.cacheTableData.length > 0 && this.reduceTableData.length > 0) {
      //   return false;
      // }
      // for (let key of this.clzCjMap.keys()) {
      //   let item = JSON.parse(this.clzCjMap.get(key));
      //   if (item.cacheTableData !== undefined && item.cacheTableData.length > 0) {
      //     return false;
      //   }
      // }
      // return true;
    },
    isShowUndoBtn() {
      return this.lastCacheCjKey === undefined || this.lastCacheCjKey === '';
    },
    doSave() {
      //console.log("开始存储");
      if (!this.reduceTableData || this.reduceTableData.length <= 0) {
        this.$message({
          type: "info",
          message: "订单不存在",
        });
        return;
      }
      this.isReduce = true;
      let tmpArr = [];
      this.lastCacheTableData = JSON.parse(JSON.stringify(this.cacheTableData))
      this.lastCacheCjKey = this.clzType + "__" + this.cjType;
      if (this.cacheTableData.length > 0) {
        // console.log("copy add");
        for (let j of this.reduceTableData) {
          let flag = false;
          for (let i of this.cacheTableData) {
            if (i.orderId == j.orderId) {
              i.number = parseInt(i.number) + parseInt(j.number);
              flag = true;
            }
          }
          if (!flag) {
            tmpArr.push(j);
          }
        }
        for (let obj of tmpArr) {
          this.cacheTableData.push(obj);
        }
      } else {
        //console.log("copy all");
        this.cacheTableData = JSON.parse(JSON.stringify(this.reduceTableData));
      }
      console.log(this.cacheTableData);
      this.cacheTime = parseInt(this.cacheTime) + 1;
      this.$message({
        type: "info",
        message: "存储成功",
      });
    },
    overSave() {
      let oriTableData = this.cacheTableData;
      let tmpCopy = "";
      let count = 0;
      let sum = 0;
      for (const iterator of oriTableData) {
        let tmpstr = iterator.orderId.slice(0, iterator.orderId.indexOf("("));
        tmpCopy = tmpCopy + (count++ == 0 ? "" : "\n") + tmpstr + "各" + iterator.number;
        sum = sum + parseInt(iterator.number);
      }
      tmpCopy = tmpCopy + "\n共计：" + sum;
      return tmpCopy;
    },
    handleClick(row) {
      //console.log(row);
      let str = this.pageInfo.km + "：" + this.clzType + "\n";
      str = str + this.pageInfo.hzChosen + "：" + this.chosenOrder + "\n";
      str = str + "用户：" + row.cust + "\n";
      str = str + this.pageInfo.vv + ": " + row.v + "\n";
      str = str + this.pageInfo.mv + ": " + row.m + "\n";
      str = str + this.pageInfo.nv + ": " + row.n + "\n";
      str = str + this.pageInfo.uv + ": " + row.u + "\n";
      str = str + this.pageInfo.wv + ": " + row.w + "\n";
      var self = this;
      this.$copyText(str).then(
        function (e) {
          self.$message({
            type: "info",
            message: "复制成功",
          });
        },
        function (e) {
          self.$message({
            type: "info",
            message: "复制失败" + e,
          });
        }
      );
    },
    doCopy(oriTableData, isCj) {
      //console.log("开始复制");
      let tmpCopy = "";
      let count = 0;
      let sum = 0;
      for (const iterator of oriTableData) {
        if (parseInt(iterator.number) <= 0) {
          continue;
        }
        let tmpstr = iterator.orderId.slice(0, iterator.orderId.indexOf("("));
        tmpCopy = tmpCopy + (count++ == 0 ? "" : "\n") + tmpstr + "各" + iterator.number;
        sum = sum + parseInt(iterator.number);
      }
      if (isCj) {
        tmpCopy = tmpCopy + "\n" + this.clzType + "共计：" + sum;
      } else {
        tmpCopy = tmpCopy + "\n共计：" + sum;
      }

      this.copyRepo = tmpCopy;
      var self = this;
      this.$copyText(this.copyRepo).then(
        function (e) {
          self.$message({
            type: "info",
            message: "复制成功",
          });
        },
        function (e) {
          self.$message({
            type: "info",
            message: "复制失败" + e,
          });
        }
      );
    },
    doCopyUpdateForm() {
      this.copyText(this.extractText(this.tmpContent));
    },
    copyText(tmpCopy) {
      var self = this;
      this.$copyText(tmpCopy).then(
        function (e) {
          self.$message({
            type: "info",
            message: "复制成功",
          });
        },
        function (e) {
          self.$message({
            type: "info",
            message: "复制失败" + e,
          });
        }
      );
    },
    clearRepo() {
      //console.log("点击清除:");
      this.reduceTableData = [];
      this.repoNum = "";
      this.cacheTableData = [];
      this.cacheTime = 0;
      for (let key of this.clzCjMap.keys()) {
        if (!key.startsWith(this.clzType + "__")) {
          continue;
        }
        let item = JSON.parse(this.clzCjMap.get(key));
        item.cacheTableData = [];
        //console.log("clear:" + JSON.stringify(item));
        this.clzCjMap.set(key, JSON.stringify(item));
      }
    },
    reduceRepo() {
      // this.repoNum = '';
      if (this.repoNum == null || this.repoNum == undefined || this.repoNum <= 0) {
        this.$message({
          type: "info",
          message: "先输入扣库数量",
        });
        return;
      }
      this.reduceTableData = this.calculReduceRepo(this.repoNum);
      if (this.tableData && this.tableData.length > 0) {
        this.isReduce = false;
        this.isPressKcc = false;
      }
      let res = this.caculAppraise(this.repoNum);
      let appraiseTable = res.appraiseTable;
      let maxKS = Number.MAX_SAFE_INTEGER;
      let maxYL = Number.MIN_SAFE_INTEGER;
      for (let i in appraiseTable) {
        if (parseFloat(appraiseTable[i].apprais) < maxKS) {
          maxKS = appraiseTable[i].apprais;
        }
        if (parseFloat(appraiseTable[i].apprais) > maxYL) {
          maxYL = appraiseTable[i].apprais;
        }
      }
      this.maxKS = parseInt(maxKS);
      this.maxYL = parseInt(maxYL);
    },
    calculReduceRepo(repoNum) {
      //console.log("计算扣除后的数据");
      let tableData = this.caculTotalUser();
      if (tableData == null || tableData == undefined || tableData.length <= 0) {
        // this.$message({
        //   type: "info",
        //   message: "当前无订单可扣",
        // });
        return [];
      }
      let tmpArr = [];
      if (tableData && tableData.length > 0) {
        for (let j of tableData) {
          if (parseInt(j.number) > 0) {
            let newobj = {};
            newobj.number = parseInt(j.number) - parseInt(repoNum);
            if (newobj.number >= 0) {
              newobj.orderId = j.orderId;
              tmpArr.push(newobj);
            }
          }
        }
      }
      return tmpArr;
    },
    restoreData() {
      //console.log("localstoratge");
      // console.log(savedData);
      let savedData = localStorage.getItem("sendResultMsg");
      if (savedData !== null) {
        this.sendResultMsg = savedData;
      }

      savedData = localStorage.getItem("tableData");
      // console.log(savedData);
      if (savedData !== null) {
        this.tableData = JSON.parse(savedData);
        this.total = 0;
        if (this.tableData && this.tableData.length > 0) {
          for (let i of this.tableData) {
            this.total = parseInt(this.total) + parseInt(i.number);
          }
        }
      }
      savedData = localStorage.getItem("desc");
      // console.log(savedData);
      if (savedData !== null) {
        this.ruleForm.desc = savedData;
      }
      savedData = localStorage.getItem("content");
      // console.log(savedData);
      if (savedData !== null) {
        this.content = savedData;
      }
      savedData = localStorage.getItem("clzType");
      // console.log(savedData);
      if (savedData !== null) {
        this.clzType = savedData;
      }
      savedData = localStorage.getItem("customerType");
      // console.log(savedData);
      if (savedData !== null) {
        this.customerType = savedData;
      }
      savedData = localStorage.getItem("clzCustMap");
      // console.log(savedData);
      if (savedData) {
        // 将 JSON 字符串解析为数组
        const mapArray = JSON.parse(savedData);
        // 将数组转换为 Map
        this.clzCustMap = new Map(mapArray);
      }
      savedData = localStorage.getItem("clzCjMap");
      // console.log(savedData);
      if (savedData) {
        // 将 JSON 字符串解析为数组
        const mapArray = JSON.parse(savedData);
        // 将数组转换为 Map
        this.clzCjMap = new Map(mapArray);
      }

      savedData = localStorage.getItem("cjType");
      // console.log(savedData);
      if (savedData !== null) {
        this.cjType = savedData;
      }
      savedData = localStorage.getItem("repoNum");
      // console.log(savedData);
      if (savedData !== null) {
        this.repoNum = savedData;
      }
      savedData = localStorage.getItem("cacheTime");
      // console.log(savedData);
      if (savedData !== null) {
        this.cacheTime = savedData;
      }
      savedData = localStorage.getItem("chosenOrder");
      // console.log(savedData);
      if (savedData !== null) {
        this.chosenOrder = savedData;
      }
      savedData = localStorage.getItem("cacheTableData");
      // console.log(savedData);
      if (savedData !== null) {
        this.cacheTableData = JSON.parse(savedData);
      }
      savedData = localStorage.getItem("reduceTableData");
      // console.log(savedData);
      if (savedData !== null) {
        this.reduceTableData = JSON.parse(savedData);
        this.reduceTotal = 0;
        if (this.reduceTableData && this.reduceTableData.length > 0) {
          for (let i of this.reduceTableData) {
            this.reduceTotal = parseInt(this.total) + parseInt(i.number);
          }
        }
      }

      savedData = localStorage.getItem("isReduce");
      // console.log(savedData);
      if (savedData !== null) {
        this.isReduce = savedData;
      }
      savedData = localStorage.getItem("isPressKcc");
      // console.log(savedData);
      if (savedData !== null) {
        this.isPressKcc = savedData;
      }
      savedData = localStorage.getItem("lastCacheCjKey");
      // console.log(savedData);
      if (savedData !== null) {
        this.lastCacheCjKey = savedData;
      }
      savedData = localStorage.getItem("lastCacheTableData");
      // console.log(savedData);
      if (savedData !== null) {
        this.lastCacheTableData = JSON.parse(savedData);
      }
    },
    onTableRowClassName(row, rowIndex) {
      if (rowIndex % 2 == 0) {
        return "statistics-warning-row";
      } else {
        return "";
      }
    },
    saveData() {
      localStorage.setItem("sendResultMsg", this.sendResultMsg);
      localStorage.setItem("desc", this.ruleForm.desc);
      localStorage.setItem("content", this.content);
      localStorage.setItem("tableData", JSON.stringify(this.tableData));
      localStorage.setItem("customerType", this.customerType);
      localStorage.setItem("clzType", this.clzType);

      localStorage.setItem("cjType", this.cjType);
      localStorage.setItem("repoNum", this.repoNum);
      localStorage.setItem("cacheTime", parseInt(this.cacheTime));
      localStorage.setItem("chosenOrder", this.chosenOrder);
      localStorage.setItem("reduceTableData", JSON.stringify(this.reduceTableData));
      localStorage.setItem("cacheTableData", JSON.stringify(this.cacheTableData));
      localStorage.setItem("isPressKcc", this.isPressKcc);
      localStorage.setItem("isReduce", this.isReduce);
      localStorage.setItem("lastCacheCjKey", this.lastCacheCjKey);
      localStorage.setItem("lastCacheTableData", JSON.stringify(this.lastCacheTableData));
      // 将 Map 转换为数组
      let mapArray = Array.from(this.clzCustMap.entries());
      // 将数组转换为 JSON 字符串
      let mapJson = JSON.stringify(mapArray);
      localStorage.setItem("clzCustMap", mapJson);

      mapArray = Array.from(this.clzCjMap.entries());
      // 将数组转换为 JSON 字符串
      mapJson = JSON.stringify(mapArray);
      localStorage.setItem("clzCjMap", mapJson);
    },
    submitForm(formName) {
      // console.log("send" + this.ruleForm);
      this.ruleForm.token = localStorage.getItem("token");
      sendContent(this.ruleForm)
        .then((res) => {
          // console.log(res);
          if (res.code == 999) {
            this.$message({
              type: "info",
              message: res.msg,
            });
            this.$router.push("/"); //登录验证成功路由实现跳转
            return;
          }
          if (res.code == 0) {
            // console.log("exception");
            this.sendResult = "";
            let tmpArr = [];
            if (this.tableData.length > 0) {
              for (let i of res.data.result) {
                let flag = false;
                for (let j of this.tableData) {
                  if (i.orderId == j.orderId) {
                    j.number = parseInt(i.number) + parseInt(j.number);
                    flag = true;
                  }
                }
                if (!flag) {
                  tmpArr.push(i);
                }
              }
              for (let obj of tmpArr) {
                this.tableData.push(obj);
              }
            } else {
              this.tableData = res.data.result;
            }
            if (this.tableData && this.tableData.length > 0) {
              this.total = 0;
              for (let i of this.tableData) {
                this.total = parseInt(this.total) + parseInt(i.number);
              }
            }

            this.sendResultMsg = "";
            let content = this.ruleForm.desc;
            if (res.data.errorMsg) {
              this.sendResultMsg = res.data.errorMsg;
            }
            console.log(content);
            this.content = this.content + this.addPTags(content);
            this.ruleForm.desc = "";
            this.$message({
              type: "info",
              message: "识别成功",
            });
          } else {
            this.$message({
              type: "info",
              message: res.msg,
            });
            this.sendResult = "error";
            this.sendResultMsg = res.data.errorMsg;
          }
          this.sortList();
        })
        .catch();
    },
    getDialogWidth() {
      return this.isPc ? "500px" : "100%";
    },
    removeLine(str, lineToRemove) {
      // 将字符串按行分割
      let lines = str.split("\n");
      // 过滤掉要删除的行
      let filteredLines = lines.filter((line) => line.trim() !== lineToRemove.trim());
      // 将过滤后的行重新拼接成字符串
      let result = filteredLines.join("\n");
      return result;
    },
    submitUpdateForm(content) {
      console.log("send" + content);
      let form = {};
      this.extractedText = this.extractText(content);
      form.desc = this.extractedText;
      form.token = localStorage.getItem("token");
      sendContent(form)
        .then((res) => {
          // console.log(res);
          if (res.code == 999) {
            this.$message({
              type: "info",
              message: res.msg,
            });
            this.$router.push("/"); //登录验证成功路由实现跳转
            return;
          }
          if (res.code == 0) {
            this.updateForm.error = "";
            if (res.data.errorMsg) {
              let linesArray = res.data.errorMsg
                .split("\n")
                .filter((line) => line.trim() !== "");
              content = this.highlightText(linesArray, content);
              console.log(content);
            } else {
              content = this.highlightText([], content);
            }
            this.content = content;
            this.tableData = res.data.result;
            if (this.tableData && this.tableData.length > 0) {
              this.total = 0;
              for (let i of this.tableData) {
                this.total = parseInt(this.total) + parseInt(i.number);
              }
            }
            this.$message({
              type: "info",
              message: "修改成功",
            });
            this.editUpdateFormVisible = false;
          } else {
            this.sendResult = "error";
            this.updateForm.error = res.data.errorMsg;
            this.$message({
              type: "info",
              message: "修改失败:" + res.data.msg,
            });
          }
        })
        .catch();
    },
    submitForm2(content) {
      console.log("send" + content);
      let form = {};
      this.extractedText = this.extractText(content);
      form.desc = this.extractedText;
      form.token = localStorage.getItem("token");
      sendContent(form)
        .then((res) => {
          // console.log(res);
          if (res.code == 999) {
            this.$message({
              type: "info",
              message: res.msg,
            });
            this.$router.push("/"); //登录验证成功路由实现跳转
            return;
          }
          if (res.code == 0) {
            this.updateForm.error = "";
            if (res.data.errorMsg) {
              let linesArray = res.data.errorMsg
                .split("\n")
                .filter((line) => line.trim() !== "");
              content = this.highlightText(linesArray, content);
              console.log(content);
              this.tmpContent = content;
            } else {
              content = this.highlightText([], content);
              console.log(content);
              this.tmpContent = content;
            }
          } else {
            this.sendResult = "error";
            this.updateForm.error = res.data.errorMsg;
          }
        })
        .catch();
    },
    resetForm(formName) {
      console.log("clear");
      this.ruleForm.desc = "";
      this.sendResultMsg = "";
      this.tableData = [];
      this.total = 0;
      this.content = "";
      this.chosenOrder = '';
      this.totalTable = [];

      if (this.isTotalCustModel) {
        console.log("clear all");
        for (let item of this.clzCustMap.keys()) {
          if (item.startsWith(this.clzType + "__")) {
            this.clzCustMap.delete(item);
          }
        }
        // 将 Map 转换为数组
        const mapArray = Array.from(this.clzCustMap.entries());
        // 将数组转换为 JSON 字符串
        const mapJson = JSON.stringify(mapArray);
        localStorage.setItem("clzCustMap", mapJson);
      } else {
        for (let item of this.clzCustMap.keys()) {
          if (item.startsWith(this.clzType + "__" + this.customerType)) {
            this.clzCustMap.delete(item);
          }
        }
      }
    },
    selectText(event) {
      // 在双击事件中选择 textarea 的文本
      const textarea = event.target; // 获取触发事件的目标元素，即 textarea
      textarea.select(); // 选择文本
    },
    getCellClass({ row, column, rowIndex, columnIndex }) {
      return "name-column-cell";
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.totalCust + this.totalCJ) {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
      if (columnIndex === 0) {
        console.log(this.totalCust);
        if (rowIndex === 0) {
          return {
            rowspan: this.totalCust,
            colspan: 1,
          };
        } else if (rowIndex === this.totalCust) {
          return {
            rowspan: this.totalCJ,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.saveData();
  },
  beforeUpdate() {
    this.saveData();
  },
  mounted() {
    console.log("mounted");
    // 添加自定义 CSS
    const style = document.createElement('style');
    style.innerHTML = `
      .ql-editor {
        font-size: 16px;
        padding: 10px;
      }
    `;
    document.head.appendChild(style);
    window.addEventListener("beforeunload", () => this.saveData());
    window.addEventListener("pagehide", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-row {
  margin-bottom: 3px;

  &:last-child {
    margin-bottom: 0;
  }
}

.pc-select {
  border: 1px solid #b8b8b8;
  /* 设置边框样式 */
  margin-left: 0%;
  margin-right: 35%;
  padding: 1%;
  width: 500px;
  /* 设置内边距 */
  box-sizing: border-box;
  background: #ffffff;
  /* transform: scale(1.5); */
  /* 将页面放大 50% */
  /* 包括内边距和边框在内的宽度和高度 */
}

.mobile-select {
  /* 在移动设备上的样式 */
  background: #ffffff;
}

.mobile-dialog {
  margin: 0%;
  padding: 0%;
  width: 100%;
}

.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-col-first {
  display: flex;
  justify-content: space-first;
  align-items: center;
}

.edit-center {
  /* padding-left: 1%; */
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #eeeef0;
}

.el-inpuy_inner .customer-color {
  color: red;
}

.fixed-width-button {
  width: 60px;
  /* 固定宽度 */
  white-space: normal;
  /* 允许换行 */
  word-break: break-all;
  align-items: center;
  /* 在需要的时候打断单词换行 */
}

.fixed-width-button-large {
  width: 50px;
  /* 固定宽度 */
  white-space: normal;
  line-height: 10px;
  /* 允许换行 */
  word-break: break-all;
  /* 在需要的时候打断单词换行 */
}

.custom-row-border {
  border: 0.5px solid #b8b8b8;
  /* 设置边框样式 */
  padding: 0px;
  margin: 0%;
  /* 设置内边距 */
  box-sizing: border-box;
  /* 包括内边距和边框在内的宽度和高度 */
}

::v-deep .small-font-select .el-input__inner {
  font-size: 10px !important;
  /* 使用 !important 强制应用字体大小 */
  color: white;
  padding: 12%;
  background: #67c23a;
}

::v-deep .small-font-select-normal .el-input__inner {
  font-size: 12px !important;
  /* 使用 !important 强制应用字体大小 */
  color: white;
  background: #67c23a;
  padding: 24%;
  align-items: center;
}

::v-deep .small-font-select-nopadding .el-input__inner {
  font-size: 10px !important;
  /* 使用 !important 强制应用字体大小 */
  color: white;
  width: 80px;
  padding-right: 12%;
  padding-left: 12%;
  height: 20px;
  background: #67c23a;
}

::v-deep .small-font-input .el-input__inner {
  font-size: 8px !important;
  /* 使用 !important 强制应用字体大小 */
  padding: 12%;
}

::v-deep .small-font-input-mini .el-input__inner {
  font-size: 8px !important;
  /* 使用 !important 强制应用字体大小 */
  padding: 0%;
}

::v-deep .small-font-button {
  font-size: 8px !important;
  /* 使用 !important 强制应用字体大小 */
  padding: 5%;
}

::v-deep .small-font-button-mini {
  align-items: center;
  font-size: 7px !important;
  /* 使用 !important 强制应用字体大小 */
  padding: 3%;
}

::v-deep .small-font-select .el-select-dropdown__item,
::v-deep .small-font-select-normal .el-select-dropdown__item {
  font-size: 5px !important;
  /* 使用 !important 强制应用字体大小 */
  color: white;
}

::v-deep .small-font-select ::placeholder,
::v-deep .small-font-select-normal ::placeholder {
  color: white !important;
  /* 设置 placeholder 的颜色 */
}

::v-deep .small-font-select .el-input__inner::placeholder,
::v-deep .small-font-select-normal .el-input__inner::placeholder {
  color: white !important;
  /* 设置 el-input 内部 placeholder 的颜色 */
}

::v-deep .small-font-select .el-input__inner::-webkit-input-placeholder,
::v-deep .small-font-select-normal .el-input__inner::-webkit-input-placeholder {
  color: white !important;
  /* 兼容 Webkit 内核浏览器 */
}

::v-deep .small-font-select .el-input__inner:-moz-placeholder,
::v-deep .small-font-select-normal .el-input__inner:-moz-placeholder {
  color: white !important;
  /* 兼容 Firefox 4-18 */
}

::v-deep .small-font-select .el-input__inner::-moz-placeholder,
::v-deep .small-font-select-normal .el-input__inner::-moz-placeholder {
  color: white !important;
  /* 兼容 Firefox 19+ */
}

::v-deep .small-font-select .el-input__inner:-ms-input-placeholder,
::v-deep .small-font-select-normal .el-input__inner:-ms-input-placeholder {
  color: white !important;
  /* 兼容 IE 10+ */
}

::v-deep .small-font-select .el-input__suffix,
::v-deep .small-font-select-normal .el-input__suffix {
  display: none !important;
  /* 隐藏下拉箭头 */
}

::v-deep .small-font-select .el-select-dropdown__item,
::v-deep .small-font-select-normal .el-select-dropdown__item {
  font-size: 5px !important;
  /* 使用 !important 强制应用字体大小 */
  color: white;
}

::v-deep .small-font-select-nopadding ::placeholder {
  color: white !important;
  /* 设置 placeholder 的颜色 */
}

::v-deep .small-font-select-nopadding .el-input__inner::placeholder {
  color: white !important;
  /* 设置 el-input 内部 placeholder 的颜色 */
}

::v-deep .small-font-select-nopadding .el-input__inner::-webkit-input-placeholder {
  color: white !important;
  /* 兼容 Webkit 内核浏览器 */
}

::v-deep .small-font-select-nopadding .el-input__inner:-moz-placeholder {
  color: white !important;
  /* 兼容 Firefox 4-18 */
}

::v-deep .small-font-select-nopadding .el-input__inner::-moz-placeholder {
  color: white !important;
  /* 兼容 Firefox 19+ */
}

::v-deep .small-font-select-nopadding .el-input__inner:-ms-input-placeholder {
  color: white !important;
  /* 兼容 IE 10+ */
}

::v-deep .small-font-select-nopadding .el-input__suffix {
  display: none !important;
  /* 隐藏下拉箭头 */
}

.name-column-cell {
  background-color: #f9f9f9;
  color: #ff4949;
}

::v-deep .el-table th,
.el-table th .cell {
  padding: 0;
  margin: 0;
  height: auto;
  /* 自动高度 */
  /* line-height: normal; */
  /* font-size: 4px; */
  /* 正常的行高 */
}

/* 设置文本大小 */
.el-table th .cell {
  font-size: 4px;
  /* 根据需要调整文本大小 */
}

::v-deep .huizong .el-table .cell {
  /* font-size: 9px; */
  /* line-height: 10px; */
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  /* 根据需要调整文本大小 */
}

::v-deep .pg .el-dialog__body {
  /* font-size: 9px; */
  /* line-height: 10px; */
  padding: 0;
  padding-top: 10px;
  /* text-align: center; */
  /* 根据需要调整文本大小 */
}

/* 设置表格边框颜色为黑色 */
::v-deep .total-table .el-table__header-wrapper th,
::v-deep .total-table .el-table__body-wrapper td,
::v-deep .total-table .el-table__body-wrapper th,
::v-deep .total-table .el-table__footer-wrapper td,
::v-deep .total-table .el-table__footer-wrapper th {
  border-top: 0.1px solid rgb(58, 57, 57);
  border-left: 0.1px solid rgb(58, 57, 57);
}

/* .el-table__row {
  border-top: 0.1px solid rgb(131, 129, 129);
  border-left: 0.1px solid rgb(112, 111, 111);
} */
</style>
